import React, { ReactElement, useCallback, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import { saveAs } from 'file-saver'

import './PropertyFloorPlan.scss'
import PropertyNavigationSliced from '../../components/PropertyNavigationSliced/PropertyNavigationSliced'
import Seo from '../../components/Seo/Seo'
import ArrowIcon from '../../assets/svg/arrow.svg'
import NextIcon from '../../assets/images/next.png'
import BackIcon from '../../assets/images/back.png'
import viewPlan from '../../assets/images/icons/view-plan.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const ImageOverlay = loadable(() =>
  import('../../components/ImageOverlay/ImageOverlay'),
)
const FullModal = loadable(() => import('../../components/FullModal/FullModal'))
const Modal = loadable(() => import('../../components/Modal/Modal'))

type FloorPlanTypes = {
  areas: {
    areaName: string
    inSqft: string
    inSqm: string
  }[]
  imageGallery?: {
    description: string
    featured: string
    url: any
  }[]
  details: string
  totalArea: string
  unitPlanFloorPlan: string
}

type TowerTypes = {
  name: string
  floorPlans?: {
    seoFloorPlanTitle: string
    seoFloorPlanDescription: string
    imageGallery?: {
      url: string
    }[]
    unitsFloorPlans?: {
      items?: FloorPlanTypes[]
    }
  }
  overviewDetails: {
    panoramas?: {
      url: string
      featured: string
    }[]
  }
}

type PropertyDataTypes = {
  id: string
  originalId: string
  name: string
  overviewDefault: string
  slug: string
  logo: string
  projectName: string
  propertyPrice: string
  propertySizeFloorArea: string
  propertySizeLotArea: string
  propertyType: string
  propertyPriceRangeHighest: string
  propertyPriceRangeLowest: string
  image1: string
  image2: string
  image3: string
  towers: TowerTypes[]
  location: string
}

type PropertyFloorPlanPropTypes = {
  data: {
    propertyData: PropertyDataTypes
  }
}

const PropertyFloorPlan = ({
  data: { propertyData },
}: PropertyFloorPlanPropTypes): ReactElement => {
  const [isLocationDetailsShowing, setIsLocationDetailsShowing] = useState<
    boolean
  >(false)
  const [isModalShowing, setIsModalShowing] = useState<boolean>(false)
  const sliderRef = useRef<Slider>(null)
  const mobileSliderRef = useRef<Slider>(null)
  const [mainTower] = useState<TowerTypes | null>(
    propertyData.towers ? propertyData.towers[0] || null : null,
  )
  const aerialView = mainTower?.overviewDetails?.panoramas
    ? mainTower?.overviewDetails?.panoramas[0]?.url
    : null
  const [floorPlans] = useState<FloorPlanTypes[]>(
    mainTower?.floorPlans?.unitsFloorPlans?.items || [],
  )
  const [SDP] = useState(
    mainTower?.floorPlans?.imageGallery
      ? mainTower?.floorPlans?.imageGallery[0]?.url
      : '',
  )
  const [
    currentFloorPlan,
    setCurrentFloorPlan,
  ] = useState<FloorPlanTypes | null>(floorPlans.length ? floorPlans[0] : null)

  const handleFloorPlanClick = useCallback(
    (title: string) => {
      setCurrentFloorPlan(
        floorPlans.find((floorPlan) => floorPlan.unitPlanFloorPlan === title) ||
          null,
      )
    },
    [floorPlans],
  )

  const imagesX = currentFloorPlan?.imageGallery
    ? [
        currentFloorPlan.imageGallery[0]?.url,
        currentFloorPlan.imageGallery[1]?.url,
      ]
    : []

  const nextImageMobile = useCallback(() => {
    if (mobileSliderRef?.current?.slickNext) {
      mobileSliderRef.current.slickNext()
    }
  }, [mobileSliderRef])

  const prevImageMobile = useCallback(() => {
    if (mobileSliderRef?.current?.slickPrev) {
      mobileSliderRef.current.slickPrev()
    }
  }, [mobileSliderRef])

  const nextImage = useCallback(() => {
    if (sliderRef?.current?.slickNext) {
      sliderRef.current.slickNext()
    }
  }, [sliderRef])

  const prevImage = useCallback(() => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef.current.slickPrev()
    }
  }, [sliderRef])

  const handleDownloadPlan = (): void => {
    if (SDP) {
      saveAs(SDP, 'site-development-plan')
    }
  }

  const seoTitle = propertyData.towers[0]?.floorPlans?.seoFloorPlanTitle
  const defaultTitle = `${propertyData.name} | Unit & Floor Plan`

  const seoDescription =
    propertyData.towers[0]?.floorPlans?.seoFloorPlanDescription

  return (
    <Layout>
      <Seo
        title={seoTitle ? seoTitle : defaultTitle}
        jsonData={{
          '@type': 'Apartment',
          description: seoDescription
            ? seoDescription
            : propertyData.overviewDefault,
        }}
      />
      <div className="property-floor-plan">
        <PropertyNavigationSliced
          propertyType={propertyData.propertyType}
          propertySlug={propertyData.slug}
          propertyLocation={propertyData.location}
        />
        <div className="property-floor-plan-details">
          <h4 className="property-floor-plan-title">Unit & Floor Plans</h4>
          {currentFloorPlan?.details ? (
            <div
              className="property-floor-plan-description"
              dangerouslySetInnerHTML={{ __html: currentFloorPlan.details }}
            />
          ) : null}
          {!!currentFloorPlan && (
            <table className="property-floor-plan-table">
              <thead>
                <tr>
                  <th>
                    <strong>{currentFloorPlan.unitPlanFloorPlan}</strong>
                    <br />
                    <span>(Approximate Sizes Only)</span>
                  </th>
                  <th>SQ.M</th>
                  <th>SQ.FT</th>
                </tr>
              </thead>
              <tbody>
                {currentFloorPlan.areas.map((area) => (
                  <tr key={area.areaName}>
                    <td>{area.areaName}</td>
                    <td>{area.inSqm}</td>
                    <td>{area.inSqft}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>Total Area</td>
                  <td>{currentFloorPlan.totalArea}</td>
                  <td>
                    {(
                      parseFloat(currentFloorPlan.totalArea || '0') * 10.7639
                    ).toFixed(0)}
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
          <h4 className="property-floor-plan-item-list-title">
            Select from the available units below:
          </h4>
          <ul className="property-floor-plan-item-list">
            {floorPlans.map((floorPlan) => (
              <li key={floorPlan.unitPlanFloorPlan}>
                <button
                  type="button"
                  className={`property-floor-plan-item${
                    floorPlan.unitPlanFloorPlan ===
                    currentFloorPlan?.unitPlanFloorPlan
                      ? ' active'
                      : ''
                  }`}
                  onClick={(): void =>
                    handleFloorPlanClick(floorPlan.unitPlanFloorPlan)
                  }
                >
                  <span className="property-floor-plan-item-type">
                    {floorPlan.unitPlanFloorPlan}
                  </span>
                  <span className="property-floor-plan-item-size">
                    Approx: {floorPlan.totalArea} sq.m
                  </span>
                  <img src={ArrowIcon} alt=">" />
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="property-floor-plan-gallery">
          <div className="gallery-content">
            <Slider
              swipeToSlide={false}
              arrows={false}
              dots={false}
              swipe={false}
              fade
              easing="ease"
              className="banner-slider"
              ref={sliderRef}
            >
              <ImageOverlay firstImage={imagesX[0]} secondImage={imagesX[1]} />
              {!!currentFloorPlan?.imageGallery &&
                currentFloorPlan?.imageGallery.map((image) => (
                  <LazyLoadImage
                    key={image.url}
                    className="slider-image"
                    src={image.url}
                    alt=""
                  />
                ))}
            </Slider>
            {!!currentFloorPlan?.imageGallery?.length && (
              <div className="nav-button-container">
                <button type="button" className="nav" onClick={prevImage}>
                  <img src={BackIcon} alt="backIcon" />
                </button>
                <button type="button" className="nav" onClick={nextImage}>
                  <img src={NextIcon} alt="nextIcon" />
                </button>
              </div>
            )}
          </div>
          <FullModal
            className="left"
            side
            isShowing={isLocationDetailsShowing}
            onClose={(): void => setIsLocationDetailsShowing(false)}
          >
            <div className="view-plan-modal">
              <LazyLoadImage className="site-dev-plan" src={SDP} alt="" />
            </div>
          </FullModal>
        </div>
      </div>
      {/* mobile view starts here */}
      <div className="property-floor-plan-mobile">
        <div className="property-floor-plan-gallery">
          <div className="gallery-content">
            <Slider
              swipeToSlide={false}
              arrows={false}
              dots={false}
              swipe={false}
              fade
              easing="ease"
              className="banner-slider"
              ref={mobileSliderRef}
            >
              <ImageOverlay firstImage={imagesX[0]} secondImage={imagesX[1]} />
              {!!currentFloorPlan?.imageGallery &&
                currentFloorPlan?.imageGallery.map((image) => (
                  <LazyLoadImage
                    key={image.url}
                    className="slider-image"
                    src={image.url}
                    alt=""
                  />
                ))}
            </Slider>
            {!!currentFloorPlan?.imageGallery?.length && (
              <div className="nav-button-container">
                <button type="button" className="nav" onClick={prevImageMobile}>
                  <img src={BackIcon} alt="backIcon" />
                </button>
                <button type="button" className="nav" onClick={nextImageMobile}>
                  <img src={NextIcon} alt="nextIcon" />
                </button>
              </div>
            )}
          </div>
        </div>
        <PropertyNavigationSliced
          propertyType={propertyData.propertyType}
          propertySlug={propertyData.slug}
          propertyLocation={propertyData.location}
          className="property-floor-plan-navigation"
        />
        <div className="property-floor-plan-details">
          <h4 className="property-floor-plan-title">Unit & Floor Plans</h4>
          {!!currentFloorPlan && (
            <table className="property-floor-plan-table">
              <thead>
                <tr>
                  <th>
                    <strong>{currentFloorPlan.unitPlanFloorPlan}</strong>
                    <br />
                    <span>(Approximate Sizes Only)</span>
                  </th>
                  <th>SQ.M</th>
                  <th>SQ.FT</th>
                </tr>
              </thead>
              <tbody>
                {currentFloorPlan.areas.map((area) => (
                  <tr key={area.areaName}>
                    <td>{area.areaName}</td>
                    <td>{area.inSqm}</td>
                    <td>{area.inSqft}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>Total Area</td>
                  <td>{currentFloorPlan.totalArea}</td>
                  <td>
                    {(
                      parseFloat(currentFloorPlan.totalArea || '0') * 10.7639
                    ).toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
          <h4 className="property-floor-plan-item-list-title">
            Select from the available units below:
          </h4>
          <ul className="property-floor-plan-item-list">
            {floorPlans.map((floorPlan) => (
              <li key={floorPlan.unitPlanFloorPlan}>
                <button
                  type="button"
                  className={`property-floor-plan-item${
                    floorPlan.unitPlanFloorPlan ===
                    currentFloorPlan?.unitPlanFloorPlan
                      ? ' active'
                      : ''
                  }`}
                  onClick={(): void =>
                    handleFloorPlanClick(floorPlan.unitPlanFloorPlan)
                  }
                >
                  <span className="property-floor-plan-item-type">
                    {floorPlan.unitPlanFloorPlan}
                  </span>
                  <span className="property-floor-plan-item-size">
                    Approx: {floorPlan.totalArea} sq.m
                  </span>
                  <img src={ArrowIcon} alt=">" />
                </button>
              </li>
            ))}
          </ul>

          <div className="property-floor-plan-gallery">
            <div className="view-button">
              <button
                type="button"
                onClick={(): void => setIsLocationDetailsShowing(true)}
              >
                <LazyLoadImage src={viewPlan} alt="view-plan" />
                <p>View Site Development Plan</p>
              </button>
            </div>
            <div className="view-button-mobile">
              <button
                type="button"
                onClick={(): void => setIsModalShowing(true)}
              >
                <img src={viewPlan} alt="view-plan" />
                <p>View Site Development Plan</p>
              </button>
              <button type="button" onClick={handleDownloadPlan}>
                <img src={viewPlan} alt="view-plan" />
                <p>Download Site Development Plan</p>
              </button>
            </div>
            <Modal
              className="left"
              noIcons
              side
              isShowing={isModalShowing}
              onClose={(): void => setIsModalShowing(false)}
            >
              <div className="view-plan-modal">
                <LazyLoadImage className="site-dev-plan" src={SDP} alt="" />
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PropertyFloorPlan

export const pageQuery = graphql`
  query PropertyFloorPlanQuery($id: String!) {
    propertyData: property(id: { eq: $id }) {
      ...PropertyPageFields
    }
  }
`
